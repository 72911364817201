const BASE_URL = 'https://gourisenrewards.com'
// const BASE_URL = 'http://localhost:3001'
// const BASE_URL = process.env.REACT_APP_BASE_URL

const routes = {
    WINNERS: BASE_URL + "/api/v1/user/winners",
    STEP1: BASE_URL + "/api/v1/user/step1",
    STEP2: BASE_URL + "/api/v1/user/step2",
    STEP3: BASE_URL + "/api/v1/user/step3",
    STEP4: BASE_URL + "/api/v1/user/step4",
    VERIFY_TOKEN: BASE_URL + "/api/v1/user/verify-token",
    RESEND_OTP: BASE_URL + "/api/v1/user/resend-otp",
    FETCH_AMOUNT: BASE_URL + "/api/v1/user/fetch-amount"
}

export default routes;
export { BASE_URL }