import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { postRequest } from '../networking/functions';
import APIS from '../networking/routes';
import { toaster } from '../networking/common';

const Upi = () => {
    const [params] = useSearchParams();
    const token = params.get("token");
    const nav = useNavigate()

    async function verifytoken() {
        try {
            const { data } = await postRequest(APIS.VERIFY_TOKEN, { token });
            if (!data.status && data.code === 203) {
                toaster(data.message, false);
                nav(-1)
            }
        } catch (err) {
            toaster(err.message, false)
        }
    }

    useEffect(() => {
        verifytoken();
    }, [token])

    const [obj, setObj] = useState({
        upi: "",
        interest: []
    });

    async function handleSumit(e) {
        e.preventDefault();
        if (obj.interest.length === 0) {
            toaster("Please select interest", false)
        } else {
            try {
                e.preventDefault()
                const { data } = await postRequest(APIS.STEP4, { ...obj, token });
                if (!data.status) {
                    toaster(data.message, false);
                } else {
                    nav("/congratulations?token=" + token)
                }
            } catch (err) {
                toaster(err.message, false)
            }
        }
    }

    function handleCheck(val) {
        if (val === "None") {
            if (obj.interest.includes(val)) {
                setObj({ ...obj, interest: [] })
            } else {
                setObj({ ...obj, interest: ["None"] })
            }

        } else {
            if (obj.interest.includes(val)) {
                setObj({ ...obj, interest: obj.interest.filter(item => (item !== val && item !== "None")) })
            } else {
                setObj({ ...obj, interest: [...obj.interest.filter(item => item !== "None"), val] })
            }
        }
    }

    return (
        <div className="login-section-common main-sectin-account-section upi-section-login">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-xl-6">
                        <div className="right-section-image-common">
                            <img src="/image/image-demo.png" alt="" />
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="box-account-area-common login-sec-main verify-section clas-common">
                            <h2>Enter your Payment Details </h2>
                            <form className="form-container " onSubmit={handleSumit}>
                                <input type="text" value={obj.upi} onChange={(e) => setObj({ ...obj, upi: e.target.value.trim() })} className="form-control" placeholder="Enter  yourUPI id " />
                                <div className="check-box-upi">
                                    <h6>interest your subject</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e) => handleCheck("Skills & Education")} checked={obj.interest.includes("Skills & Education")} value="Skills & Education" id="flexCheckDefault" />
                                        <label className="form-check-label" for="flexCheckDefault">
                                            Skill & Education
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="Income" id="flexCheckChecked"
                                            onChange={(e) => handleCheck("Income")} checked={obj.interest.includes("Income")} />
                                        <label className="form-check-label" for="flexCheckChecked">
                                            Income
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" onChange={(e) => handleCheck("None")} checked={obj.interest.includes("None")} type="checkbox" value="None" id="flexCheckDefault1" />
                                        <label className="form-check-label" for="flexCheckDefault1">
                                            None of them
                                        </label>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-success w-100">Verify & Continue</button>
                                <button type="button" onClick={() => nav(-1)} className="btn Default-color w-100">Cancel </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Upi