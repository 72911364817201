import React from 'react'

const Footer = () => {
    return (
        <footer className="footer-section py-5">
            <div className="container">
                <div className="row">
                    {/* <!-- Company Section --> */}
                    <div className=" col-xl-4 col-lg-4 col-md-4 col-md-6">
                        <div className="mb-3 foote-logo">
                            <a href="#"><img src="/image/logo.png" alt="Gourisen Private Limited" className="img-fluid" /></a>
                        </div>
                        <div className="mb-3 footer-social-icon">
                            <a href="#"><i className="fab footer-social-icon  fa-facebook"></i></a>
                            <a href="#"><i className="fab footer-social-icon fa-instagram"></i></a>
                            <a href="#"><i className="fab footer-social-icon fa-youtube"></i></a>
                        </div>
                    </div>

                    {/* <!-- Useful Links Section --> */}
                    <div className="col-xl-3 col-lg-3 col-md-3 col-md-6 footer-heading">
                        <h5 className="mb-4">Useful links</h5>
                        <ul className="list-unstyled">
                            <li><a href="#">All Courses</a></li>
                            <li><a href="#">About Us</a></li>
                            <li><a href="#">Gallery</a></li>
                            <li><a href="#">Blog</a></li>
                        </ul>
                    </div>

                    {/* <!-- Support Section --> */}
                    <div className="col-xl-3 col-lg-3 col-md-3 col-md-6 footer-heading">
                        <h5 className="mb-4">Support</h5>
                        <ul className="list-unstyled">
                            <li><a href="#">FAQ</a></li>
                            <li><a href="#">Contact Us</a></li>
                            <li><a href="#">Refund Policy</a></li>
                        </ul>
                    </div>

                    {/* <!-- Subscribe Section --> */}
                    <div className="col-xl-2 col-lg-2 col-md-2 col-md-6 foote-subscribe">
                        <h5 className="mb-4">Subscribe</h5>
                        <form>
                            <div className="input-group mb-3">
                                <input type="email" className="form-control" placeholder="Email address" />
                                <button className="btn btn-secondary" type="submit"><i className="fas fa-arrow-right"></i></button>
                            </div>
                            <p className="small text-white">Hello, we are Life Media. Our goal is to translate the positive
                                effects from
                                revolutionizing how companies engage with their clients & their team.</p>
                        </form>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer