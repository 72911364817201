import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getRequest, postRequest } from '../networking/functions'
import APIS, { BASE_URL } from '../networking/routes'
import moment from 'moment/moment'
import { toaster } from '../networking/common'
import No_data_row from '../components/No_data_row'

const TableRow = ({ sr, amount, name, timestamp, proof, link = "" }) => {
    return (
        <tr>
            <th scope="row">{sr}</th>
            <td>{amount}</td>
            <td>{name}</td>
            <td>{moment(timestamp).format("DD-MM-YYYY hh:mm A")}</td>
            <td><Link to={BASE_URL + "/"+proof} target='_blank' download><img src="/image/icon-download.png" alt="Download" /></Link></td>
            <td><Link to={link} target='_blank'><i className="fa-solid fa-link"></i></Link></td>
        </tr>
    )
}

const Reward = () => {
    const nav = useNavigate()
    const [winners, setWinners] = useState([])
    const LIMIT = 5
    const [total_count, setTotalCount] = useState(0);
    const [skip, setSkip] = useState(0);
    const [current, setNumber] = useState(1)
    const [obj, setObj] = useState({
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
        city: "",
        state: "",
        pincode: ""
    })

    function handleChanges(e) {
        if (e.target.name === "mobile" || e.target.name === "pincode") {
            if (!isNaN(e.target.value)) {
                setObj({
                    ...obj,
                    [e.target.name]: e.target.value.trim()
                })
            }
        } else {
            setObj({
                ...obj,
                [e.target.name]: e.target.value
            })
        }
    }

    function handlePage(number) {
        setNumber(number)
        setSkip((number - 1) * LIMIT)
    }

    async function getWinners() {
        try {
            const { data } = await getRequest(`${APIS.WINNERS}?skip=${skip}&limit=${LIMIT}`);
            if (data.status) {
                setWinners([...data.data])
                setTotalCount(data.total_count)
            } else {
                toaster(data.message, false)
            }
        } catch (err) {
            toaster(err.message, false)
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            const { data } = await postRequest(APIS.STEP1, obj);
            if (data.status) {
                nav("/verify-otp?token="+data.token)
            } else {
                toaster(data.message, false)
            }
        } catch (err) {
            toaster(err.message, false)
        }
    }

    useEffect(() => {
        getWinners()
    }, [skip])


    return (
        <div className="login-section-common main-sectin-account-section">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-xl-7 col-lg-7 ">
                        <div className="box-account-area-common login-sec-main">
                            <h2>Fill the details claim the reward </h2>
                            <form className="form-container" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6">
                                        <label for="">First Name</label>
                                        <input type="text" name="firstname" value={obj.firstname} onChange={handleChanges} className="form-control" placeholder="Enter the First Name" />
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6">
                                        <label for="">Last Name</label>
                                        <input type="text" name="lastname" value={obj.lastname} onChange={handleChanges} className="form-control" placeholder="Enter the Last Name" />
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6">
                                        <label for="">Mobile Number</label>
                                        <input type="text" name="mobile" maxLength={10} value={obj.mobile} onChange={handleChanges} className="form-control" placeholder="Enter the Mobile Number" />
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6">
                                        <label for="">Email ID</label>
                                        <input type="email" name="email" value={obj.email} onChange={handleChanges} className="form-control" placeholder="Enter the Email ID" />
                                    </div>
                                    <div className="col-md-4">
                                        <label for="">City</label>
                                        <input type="text" name="city" value={obj.city} onChange={handleChanges} className="form-control" placeholder="" />
                                    </div>
                                    <div className="col-md-4">
                                        <label for="">State</label>
                                        <input type="text" name="state" value={obj.state} onChange={handleChanges} className="form-control" placeholder="" />
                                    </div>
                                    <div className="col-md-4">
                                        <label for="">Pin Code</label>
                                        <input type="text" name="pincode" maxLength={6} value={obj.pincode} onChange={handleChanges} className="form-control" placeholder="" />
                                    </div>

                                </div>
                                <button type="submit" className="btn btn-success w-100">Submit Details</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-5 ">
                        <div className="right-section-image-common">
                            <h2>Claim your <span> Reward</span></h2>
                            <img src="/image/right-img.png" alt="" />
                        </div>
                    </div>
                </div>

                <div className="login-table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">SL No</th>
                                <th scope="col"> Coupon Amount</th>
                                <th scope="col">Redeemed By</th>
                                <th scope="col">Redeemed On</th>
                                <th scope="col">Payment Proof</th>
                                <th scope="col">Coupon Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            {winners.map((item, index) => <TableRow amount={item.amount} name={item.name} sr={index + 1} timestamp={item.redeemedon} proof={item.proof} link={item.link} />)}
                            {winners.length <= 0 && <No_data_row />}
                        </tbody>
                    </table>



                    {total_count > LIMIT && <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-item">
                                <a className="page-link" onClick={() => {
                                    if (current > 0) {
                                        handlePage(current - 1)
                                    }
                                }} aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            {Array.from({ length: Math.ceil(total_count / LIMIT) }, (_, i) => i + 1).map(item => <li className="page-item"><a className="page-link" onClick={() => handlePage(item)}>{item}</a></li>)}
                            <li className="page-item">
                                <a className="page-link" onClick={() => {
                                    if (current < Math.ceil(total_count / LIMIT)) {
                                        handlePage(current + 1)
                                    }
                                }} aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>}
                </div>
            </div>
        </div>
    )
}

export default Reward