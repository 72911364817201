import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Coupon from '../pages/Coupon'
import Reward from '../pages/Reward'
import Otp from '../pages/Otp'
import Congrats from '../pages/Congrats'
import Upi from '../pages/Upi'

const Routing = () => {
    return (
        <Routes>
            <Route path='/' element={<Reward />} exact />
            <Route path='/coupon' element={<Coupon />} exact />
            <Route path='/verify-otp' element={<Otp />} exact />
            <Route path='/payment-details' element={<Upi />} exact />
            <Route path='/congratulations' element={<Congrats />} exact />
        </Routes>
    )
}

export default Routing