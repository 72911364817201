import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { postRequest } from '../networking/functions';
import APIS from '../networking/routes';
import { toaster } from '../networking/common';

const Otp = () => {
    const [params] = useSearchParams();
    const token = params.get("token");
    const nav = useNavigate()
    const [otp, setOtp] = useState(Array(4).fill(''));

    const handlePaste = (e) => {
        const paste = e.clipboardData.getData('text');
        if (/^\d{4}$/.test(paste)) {
            const newOtp = paste.split('');
            setOtp(newOtp);
            inputRefs.current[newOtp.length - 1].focus();
        }
    };

    async function verifytoken() {
        try {
            const { data } = await postRequest(APIS.VERIFY_TOKEN, { token });
            if (!data.status && data.code === 203) {
                toaster(data.message, false);
                nav(-1)
            }
        } catch (err) {
            toaster(err.message, false)
        }
    }


    const inputRefs = useRef([]);

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value)) { // Ensuring only numbers are entered
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };


    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && index > 0 && !e.target.value) {
            inputRefs.current[index - 1].focus();
        }
    };

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            const { data } = await postRequest(APIS.STEP2, { otp: otp.join(''), token });
            if (data.status) {
                nav("/coupon?token=" + data.token)
            } else {
                toaster(data.message, false)
            }
        } catch (err) {
            toaster(err.message, false)
        }
    }

    async function resendOtp() {
        try {
            const { data } = await postRequest(APIS.RESEND_OTP, { token });
            if (data.status) {
                toaster(data.message, true)
            } else {
                toaster(data.message, false)
            }
        } catch (err) {
            toaster(err.message, false)
        }
    }

    useEffect(() => {
        verifytoken();
    }, [token])

    return (
        <div className="login-section-common main-sectin-account-section login-passowrdps-section">
            <div className="container">
                <div className="row ">

                    <div className="col-xl-6">
                        <div className="right-section-image-common">

                            <img src="/image/login-pass.png" alt="" />
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="box-account-area-common verify-section otp-section-box">
                            <h2>Please Verify Account</h2>
                            <form className="form-container " onSubmit={handleSubmit}>
                                <div className="row">

                                    <div className="col-md-12">
                                        <label for="password">Enter the OTP</label>
                                        <div className="row">
                                            {Array(4).fill('').map((_, index) => (
                                                <div className="col-3 "><input key={index}
                                                    ref={el => inputRefs.current[index] = el}
                                                    type="tel"
                                                    inputMode="numeric"
                                                    maxLength="1"
                                                    onPaste={handlePaste}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    onKeyDown={(e) => handleKeyDown(e, index)} id="type" className="form-control"
                                                    placeholder="0" /></div>
                                            ))}
                                        </div>
                                        <div className="resend-otp">
                                            <a onClick={resendOtp}>Resend OTP</a>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-success w-100">Verify & Continue</button>
                                <button type="button" onClick={() => nav(-1)} className="btn Default-color w-100">Cancel </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Otp