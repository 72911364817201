import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getRequest, postRequest } from '../networking/functions';
import APIS from '../networking/routes';
import { toaster } from '../networking/common';

const Congrats = () => {
    const [params] = useSearchParams();
    const token = params.get("token");
    const nav = useNavigate()
    const [amount,setAmount] = useState(0);

    async function verifytoken() {
        try {
            const { data } = await getRequest(APIS.FETCH_AMOUNT+"?token="+token);
            if (!data.status && data.code === 203) {
                toaster(data.message, false);
                nav(-1)
            }else{
                setAmount(data.data.amount)
            }
        } catch (err) {
            toaster(err.message, false)
        }
    }

    useEffect(() => {
        verifytoken();
    }, [token])

    return (
        <div className="congratulatin-section main-sectin-account-section">
            <div className="container">
                <div className="main-section-cong">
                    <img src="/image/image-congratulation.png" alt="" />
                    <div className="cong-content">
                        <h2>Congress</h2>
                        <p>Your account will be credited with the {amount} INR within the next 72 hours.</p>
                        <div className="common-section">
                            <p>if you don't fill out the details again and again <span>signup now</span> for a faster
                                rewards claim
                                process.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Congrats