import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toaster } from '../networking/common';
import { postRequest } from '../networking/functions';
import APIS from '../networking/routes';

const Coupon = () => {
    const [params] = useSearchParams();
    const token = params.get("token");
    const nav = useNavigate()
    const [coupon, setCoupon] = useState('')

    async function verifytoken() {
        try {
            const { data } = await postRequest(APIS.VERIFY_TOKEN, { token });
            if (!data.status && data.code === 203) {
                toaster(data.message, false);
                nav(-1)
            }
        } catch (err) {
            toaster(err.message, false)
        }
    }

    useEffect(() => {
        verifytoken();
    }, [token])

    async function verifyCoupon(e) {
        try {
            e.preventDefault()
            const { data } = await postRequest(APIS.STEP3, { code: coupon, token });
            if (!data.status) {
                toaster(data.message, false);
            } else {
                nav("/payment-details?token=" + data.token)
            }
        } catch (err) {
            toaster(err.message, false)
        }
    }

    return (
        <div className="login-section-common main-sectin-account-section">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-xl-6">
                        <div className="box-account-area-common login-sec-main verify-section clas-common">
                            <h2>Please Enter Your coupon No</h2>
                            <form className="form-container " onSubmit={verifyCoupon}>
                                <input type="text" value={coupon} onChange={(e) => setCoupon(e.target.value.toUpperCase().trim())} className="form-control" placeholder="Enter Your Coupon" />
                                <button type="submit" className="btn btn-success w-100">Verify & Continue</button>
                                <button type="button" onClick={() => nav(-1)} className="btn Default-color w-100">Cancel </button>
                            </form>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="right-section-image-common">

                            <img src="/image/Group 3260.png" alt="" />
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Coupon