import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <header className="header">
            <div className="container d-flex justify-content-between align-items-center">
                <div className="common-header">
                    <div className="logo-section"><Link to="/"><img src="/image/logo.jpg" alt="Logo"/></Link></div>
                </div>
                {/* <div className="header-common-righ">
                    <a className="user-icon" href="#"><img src="/image/user-login.png" alt=""/></a>
                    <a className="logout-button" href="#">Logout <img src="/image/Vector (2).png" alt=""/></a>
                </div> */}
            </div>


            <div className="sidebar" id='sidebar'>
                <ul>
                    <li><a href="">Home</a></li>
                    <li><a href="">About Us</a></li>
                    <li><a href="">Our Course</a></li>
                    <li><a href="">Contact Us</a></li>
                </ul>
            </div>
        </header>
    )
}

export default Header