import { BASE_URL } from './routes'
import axios from 'axios'

var TOKEN = ""

const instance = axios.create({
    baseURL: BASE_URL+"",
    headers: { 'x-token': TOKEN }
});

let router = null

// Function to set the token in both localStorage and the Axios instance
function setToken(newToken) {
    TOKEN = newToken;
    localStorage.setItem('x_token', newToken);
    instance.defaults.headers['token'] = newToken;
}

// Initially check if a token exists in localStorage
const storedToken = localStorage.getItem('x_token');
if (storedToken) {
    setToken(storedToken);
}

function setRouter(context){
    router = context
}

instance.interceptors.response.use(function (response) {
    if (response.data.code === 401) {
        localStorage.removeItem('x_token');
        localStorage.removeItem('info');
        router("/login")
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

// Get Request
const getRequest = (path) => {
    return instance.get(path);
}

// Post Request
const postRequest = (path, data) => {
    return instance.post(path, data);
}

// Put Request
const putRequest = (path, data) => {
    return instance.put(path, data);
}

// Patch Request
const patchRequest = (path, data) => {
    return instance.patch(path, data);
}

// Delete Request
const deleteRequest = (path) => {
    return instance.delete(path);
}

export { getRequest, postRequest, putRequest, patchRequest, deleteRequest, setToken, setRouter }